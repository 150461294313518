import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"

import Box from "../../components/box"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import { GatsbyImage } from "gatsby-plugin-image"
import Stack from "../../components/stack"

const VideoThumbnail = ({ to, key, title, src }) => {
  return (
    <Link to={to} key={key}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          ":hover .video-play-button, :focus .video-play-button": {
            bg: "black",
            color: "white",
          },
          ":hover .video-heading, :focus .video-heading": {
            textDecoration: "underline",
          },
        }}
      >
        <Stack space={3}>
          <Box sx={{ width: "100%", position: "relative" }}>
            <GatsbyImage image={src} alt={`Titelbild Video ${title}`} />
            <Box
              className="video-play-button"
              sx={{
                width: [12, 12, 16],
                height: [12, 12, 16],
                borderRadius: "100%",
                bg: "primary",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Icon icon="play" size={[6, 6, 8]} />
            </Box>
          </Box>
          <Heading as="h2" className="video-heading">
            {title}
          </Heading>
        </Stack>
      </Box>
    </Link>
  )
}

const GebaerdenVideoGrid = ({ active }) => {
  const data = useStaticQuery(graphql`
    query GebaerdenVideoGridIndexQuery {
      turmzimmer: file(relativePath: { eq: "sprachen/turmzimmer.jpg" }) {
        ...largeImage
      }
      aussen: file(relativePath: { eq: "sprachen/aussen.jpg" }) {
        ...largeImage
      }
      mediaguide: file(relativePath: { eq: "leichte-sprache/mediaguide.jpg" }) {
        ...largeImage
      }
      garten: file(relativePath: { eq: "sprachen/garten.jpg" }) {
        ...largeImage
      }
      besuch: file(relativePath: { eq: "sprachen/ihr-besuch.jpg" }) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/leichte-sprache.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Grid columns={[2, 3, 4]}>
      {active === "ueber-den-hoelderlinturm" ? null : (
        <VideoThumbnail
          src={data.aussen.childImageSharp.gatsbyImageData}
          key="ueber-den-hoelderlinturm"
          title="Über den Hölderlinturm"
          to="/gebaerdensprache/ueber-den-hoelderlinturm"
          alt="Eine Außenansicht vom Museum Hölderlinturm. Das kleine gelbe Haus mit dem Spitzturm liegt direkt am Neckar."
        />
      )}
      {active === "dauerausstellung" ? null : (
        <VideoThumbnail
          src={data.turmzimmer.childImageSharp.gatsbyImageData}
          key="dauerausstellung"
          title="Die Dauerausstellung im Hölderlinturm"
          to="/gebaerdensprache/dauerausstellung"
          alt="Hölderlins Turmzimmer in der Dauerausstellung: Der Raum hat drei Fenster. In der Mitte steht ein Schreibtisch. An der Wand ist Hölderlins Gedicht Aussicht zu lesen."
        />
      )}
      {active === "mediaguide" ? null : (
        <VideoThumbnail
          src={data.mediaguide.childImageSharp.gatsbyImageData}
          key="mediaguide"
          title="Media-Guide mit Inhalten in Gebärdensprache"
          to="/gebaerdensprache/mediaguide"
          alt="Eine Besucherin wählt auf dem Media-Guide-Gerät den Rundgang in Deutscher Gebärdensprache aus. "
        />
      )}
      {active === "garten" ? null : (
        <VideoThumbnail
          src={data.garten.childImageSharp.gatsbyImageData}
          key="garten"
          title="Der Museumsgarten"
          to="/gebaerdensprache/garten"
          alt="Der Museumsgarten. Eine große Steintreppe führt hinunter zum Neckar."
        />
      )}
      {active === "wegbeschreibungen" ? null : (
        <VideoThumbnail
          src={data.besuch.childImageSharp.gatsbyImageData}
          key="wegbeschreibungen"
          title="So kommt man zum Hölderlinturm"
          to="/gebaerdensprache/wegbeschreibungen"
          alt="Der Museumsvorplatz mit der Eingangstür zum Museum."
        />
      )}
    </Grid>
  )
}

GebaerdenVideoGrid.propTypes = {
  active: PropTypes.string,
}

export default GebaerdenVideoGrid
