import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Badge from "../../components/badge"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Divider from "../../components/divider"
import GebaerdenVideoGrid from "../../content/shared/gebaerden-video-grid"
import Heading from "../../components/heading"
import Inline from "../../components/inline"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const GebaerdenspracheWegbeschreibungen = () => {
  const data = useStaticQuery(graphql`
    query GebaerdenspracheWegbeschreibungenQuery {
      posterBus: file(relativePath: { eq: "videos/poster-bus.png" }) {
        ...largeImage
      }
      posterAuto: file(relativePath: { eq: "videos/poster-auto.png" }) {
        ...largeImage
      }
      posterZug: file(relativePath: { eq: "videos/poster-zug.png" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="wegbeschreibung">
      <Seo
        title="So kommen Sie zum Hölderlinturm"
        description="In diesen 3 Videos zeigen wir Ihnen, wie Sie mit dem Bus, mit dem Auto oder mit dem Zug zum Museum Hölderlinturm kommen."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Gebärdensprache",
                link: "/gebaerdensprache",
              },
              {
                title: "Wegbeschreibungen",
                link: "/gebaerdensprache/wegbeschreibungen",
              },
            ]}
          />
          <PageTitle>So kommen Sie zum Hölderlinturm</PageTitle>
          <Paragraph>
            Das ist die Adresse vom Hölderlinturm: <br />
            Bursagasse 6 <br />
            72070 Tübingen
          </Paragraph>
          <Paragraph>
            Der Weg zum Turm ist nicht barrierefrei. <br />
            Mit dem Rollstuhl kommt man nicht zum Turm.
          </Paragraph>
        </Stack>

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Wenn Sie mit dem Bus kommen:
            </Heading>
            <VideoPlayer src="wegbeschreibungen/bus" poster={data.posterBus} />
            <Paragraph>
              Mit diesen Linien kommen Sie zum Hölderlinturm:
            </Paragraph>
            <Inline>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                1
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                2
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                3
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                4
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                5
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                6
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                7
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                8
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                9
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                10
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                11
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                12
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                13
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                X15
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                16
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                17
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                19
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                21
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                22
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                23
              </Badge>
            </Inline>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Wenn Sie mit dem Auto kommen:
            </Heading>
            <VideoPlayer
              src="wegbeschreibungen/auto"
              poster={data.posterAuto}
            />
            <Paragraph>
              Sie können im Neckar-Parkhaus parken: <br />
              Wöhrdstraße 11 <br />
              72072 Tübingen
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Wenn Sie mit dem Zug kommen:
            </Heading>
            <VideoPlayer src="wegbeschreibungen/zug" poster={data.posterZug} />
          </Stack>
        </Constrain>
        <Stack>
          <Divider size={4} />
          <GebaerdenVideoGrid active="wegbeschreibungen" />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default GebaerdenspracheWegbeschreibungen
